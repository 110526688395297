<template>
  <footer class="footer pb-0 footer-block">
    <div class="container mx-auto text-white pb-4 footer-desktop">
      <div class="row justify-content-between">
        <div class="col-lg-3 col-md-12">
          <div class="footer-logo">

          </div>
        </div>
        <div class="col-lg-3 col-md-12">
          <ul class="footer_menu">
            <!-- <li>
                <router-link tag="a" to="/">{{ $t('footer.contacts') }}</router-link>
            </li> -->
            <li>
              <router-link tag="a" to="/">
                {{ $t('breadcrumb.main-page') }}
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/about-us">
                {{ $t('breadcrumb.about') }}
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/for-members">
                {{ $t('breadcrumb.for-members') }}
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/for-teachers">
                {{ $t('breadcrumb.for-teachers') }}
              </router-link>
            </li>

          </ul>
        </div>
        <div class="col-lg-2 col-md-12">
          <ul class="footer_menu">
            <li>
              <router-link tag="a" to="/for-parents">
                {{ $t('breadcrumb.for-parents') }}
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/centers">
                {{ $t('navbar.qabilet-centers') }}
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/faq">
                {{ $t('breadcrumb.faq') }}
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/privacy-policy">
                {{ $t('footer.privacy-policy') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-12">
          <ul class="footer_menu">
            <li>
              <router-link tag="a" to="/terms-of-use">
                {{ $t('footer.terms-of-use') }}
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/library-regulations">
                {{ $t('footer.library-regulations') }}
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/public-offer">
                {{ $t('footer.public-offer') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class=" d-none col-lg-4 col-md-12 d-none d-lg-block invisible">
          <div class="mobile-apps-wrap d-none">
            <a href="#"><img src="/images/google-badge.svg" width="168" alt=""></a>
            <a href="#"><img src="/images/appstore-badge.svg" width="168" alt=""></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom footer-bottom-links footer-bottom-desktop">
      <div class="container">
        <p class="mb-0">© {{ date }} Qabilet. {{ $t('footer.copyright') }}</p>
        <div class="footer-links-block">
          <a class="youtube-link" href="https://www.youtube.com/channel/UC9nAqSXFXJSJi5qe1xBTNTA">

          </a>
          <a class="telegram-link" href="https://t.me/qabiletevent">

          </a>
          <a class="facebook-link" href="https://discord.com/invite/59HCjjRCZH">

          </a>
          <a class="instagram-link" href="https://www.instagram.com/qabilet_elumiti.kz/">

          </a>
          <a href="tel:87273100258" class="phone-link">
            8 727 310 02 58
          </a>
        </div>
      </div>
    </div>


    <div class="container mx-auto text-white pb-4 footer-mobile">
      <div class="row justify-content-between">
        <div class="col-md-12">
          <div class="footer-logo">

          </div>
        </div>
        <div class="container">
          <div class="footer-links-mobile">
            <a class="youtube-link youtube-link-mobile" href="https://www.youtube.com/channel/UC9nAqSXFXJSJi5qe1xBTNTA">

            </a>
            <a class="telegram-link telegram-link-mobile" href="https://t.me/qabiletevent">

            </a>
            <a class="facebook-link facebook-link-mobile" href="https://discord.com/invite/59HCjjRCZH">

            </a>
            <a class="instagram-link instagram-link-mobile" href="https://www.instagram.com/qabilet_elumiti.kz/">

            </a>
            <a class="phone-link phone-link-mobile">
              8 727 310 02 58
            </a>
          </div>
        </div>

        <div class="d-flex w-100">
          <div class="col-md-6">
            <ul class="footer_menu">
              <li>
                <router-link tag="a" to="/">
                  {{ $t('breadcrumb.main-page') }}
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/about-us">
                  {{ $t('breadcrumb.about') }}
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/for-members">
                  {{ $t('breadcrumb.for-members') }}
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/for-teachers">
                  {{ $t('breadcrumb.for-teachers') }}
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/for-parents">
                  {{ $t('breadcrumb.for-parents') }}
                </router-link>
              </li>

            </ul>
          </div>
          <div class="col-md-6">
            <ul class="footer_menu">
              <li>
                <router-link tag="a" to="/centers">
                  {{ $t('navbar.qabilet-centers') }}
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/">
                  FAQ
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/privacy-policy">
                  {{ $t('footer.privacy-policy') }}
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/terms-of-use">
                  {{ $t('footer.terms-of-use') }}
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/library-regulations">
                  {{ $t('footer.library-regulations') }}
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/public-offer">
                  {{ $t('footer.public-offer') }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class=" d-none col-lg-4 col-md-12 d-none d-lg-block invisible">
          <div class="mobile-apps-wrap d-none">
            <a href="#"><img src="/images/google-badge.svg" width="168" alt=""></a>
            <a href="#"><img src="/images/appstore-badge.svg" width="168" alt=""></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom footer-bottom-links footer-bottom-mobile">
      <div class="container">
        <p>© {{ date }} Qabilet. {{ $t('footer.copyright') }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      date: new Date().getFullYear()
    }
  }
}
</script>

<style>

.footer-mobile {
  display: none;
}

.footer-bottom-mobile {
  display: none;
}

@media screen and (max-width: 992px) {

  .footer-mobile {
    display: block;
  }

  .footer-bottom-mobile {
    display: block;
  }

  .footer-desktop, .footer-bottom-desktop {
    display: none;
  }

  .footer-block {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding-top: 24px !important;
    background: #266390 !important;
  }

  .footer-mobile .footer-logo {
    height: 32px;
    margin-bottom: 20px;
  }

  .footer-links-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-bottom: 30px;
  }

  .footer-mobile .footer_menu {
    text-align: left;
  }

  .footer-bottom-mobile {
  }

  .footer-bottom-mobile p {
    margin: 0;
  }

  .footer .footer-bottom {
    border-top: 1px solid #EBEBEB !important;
    padding: 24px 0 !important;
  }

  .footer-bottom-links .container {
    justify-content: center !important;
  }

}

.our-app {
  display: none;
}

</style>
