<template>
  <footer class="footer pb-0 footer-block" style="border-top-left-radius : 0; border-top-right-radius: 0">
    <div class="container mx-auto text-white pb-4">
      <div class="row justify-content-between">
        <div class="col-lg-3 col-md-12">
          <div class="footer-logo">

          </div>
        </div>
        <div class="col-lg-3 col-md-12">
          <ul class="footer_menu">
            <!-- <li>
                <router-link tag="a" to="/">{{ $t('footer.contacts') }}</router-link>
            </li> -->
            <li>
              <router-link tag="a" to="/terms-of-use">
                Главная
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/privacy-policy">
                О проекте
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/moderation-policy">
                Участникам тестирования
              </router-link>
            </li>

          </ul>
        </div>
        <div class="col-lg-2 col-md-12">
          <ul class="footer_menu">
            <li>
              <router-link tag="a" to="/terms-of-use">
                Педагогам
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/privacy-policy">
                Родителям
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/moderation-policy">
                Центры "Qabilet"
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-12">
          <ul class="footer_menu">
            <li>
              <router-link tag="a" to="/terms-of-use">
                FAQ
              </router-link>
            </li>
          </ul>
        </div>
        <div class=" d-none col-lg-4 col-md-12 d-none d-lg-block invisible">
          <div class="mobile-apps-wrap d-none">
            <a href="#"><img src="/images/google-badge.svg" width="168" alt=""></a>
            <a href="#"><img src="/images/appstore-badge.svg" width="168" alt=""></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom footer-bottom-links">
      <div class="container">
        <p class="mb-0">© {{ date }} Qabilet. {{ $t('footer.copyright') }}</p>
        <div class="footer-links-block">
          <a class="youtube-link">

          </a>
          <a class="facebook-link">

          </a>
          <a class="instagram-link">

          </a>
          <a class="phone-link">
            8 727 310 02 58
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      date: new Date().getFullYear()
    }
  }
}
</script>

<style>
.our-app {
  display: none;
}
</style>
